<template>
    <div class="h-100">
      <div v-if="rldata.screen_flags.show_child_info" class="p-5 h-75 d-flex flex-column justify-content-between">
        

        

            <div class="row">
                <div class="card-body col-12 col-md-6 p-0">
                    <h3 class="mb-3">Child’s account information</h3>
                    <div class="row">
                        <form  class="forms-sample col-12 col-lg-5 text-center">
                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Username</span>
                                </div> -->
                                <input type="text" id="username" class="form-control" aria-label="Username" aria-describedby="basic-addon1" readonly v-model="rldata.selected_child.username">
                            </div>

                            <div class="input-group" v-if="rldata.selected_child.grade != null">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Grade</span>
                                </div> -->
                                <input type="text" id="grade" class="form-control" aria-label="Grade" aria-describedby="basic-addon1" readonly v-model="'Grade ' + rldata.selected_child.grade">
                            </div>
                        </form>

                        <!-- <div class="offset-0 offset-md-2 mt-3 mt-md-0 d-flex flex-column col-12 col-md-4">
                            <div style="position: relative;" class="border rounded border-secondary d-flex justify-content-between align-items-center mb-3" v-bind:class="{ 'disabled_social p-2': rldata.user.gg_id }">
                                <div class="ulogin-button-google social_img_cards social_google"></div>
                                <p v-if="!(rldata.user.gg_id)" class="m-0 social_connect w-100 text-end p-2" v-on:click="add_social" data-uloginbutton="Google">Connect</p>
                                <p v-else class="m-0"><i class="mdi mdi-24px mdi-check-bold mr-2 text-primary"></i></p>
                            </div>

                            <div style="position: relative;" class="border rounded border-secondary d-flex justify-content-between align-items-center" v-bind:class="{ 'disabled_social p-2': rldata.user.gg_id }">
                                <div class="ulogin-button-clever social_img_cards social_clever"></div>
                                <p v-if="!(rldata.user.gg_id)" class="m-0 social_connect w-100 text-end p-2" v-on:click="add_social" data-uloginbutton="Clever">Connect</p>
                                <p v-else class="m-0"><i class="mdi mdi-24px mdi-check-bold mr-2 text-primary"></i></p>
                            </div>
                        </div>   -->
                    </div>

                </div>
                <div class="col-12 col-lg-4 mt-3 mt-lg-0 d-flex flex-column justify-content-end">
                    <!-- <div>
                        <button type="submit" class="btn btn-warning w-100 mb-3">Block {{rldata.selected_child.first_name}}'s account</button>
                        <button type="submit" class="btn btn-danger w-100">Remove {{rldata.selected_child.first_name}}'s account</button>
                    </div> -->

                    <button type="submit" class="switch_to_child_acc_btn" @click="$root.go_to_childs_account"><i class="mdi mdi-exit-to-app"></i> Go to {{rldata.selected_child.first_name}}'s account</button>
                </div>
            </div>
            

            <!-- <div class="row mt-5">
                <div class="card-body col-12 col-md-5 bg-warning">
                    <h4>Set {{rldata.selected_child.first_name}}'s grade</h4>
                    <form v-on:submit.prevent="update__grade" class="forms-sample text-center mt-3">

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Grade</span>
                            </div>
                            <input type="number" id="new_grade" min="0" max="3" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__child_data.grade">
                        </div>

                        <button type="submit" class="btn btn-dark w-100">Save</button>
                    </form>
                </div>
                <div class="d-none d-md-flex col-7"></div>
            </div> -->

            <div class="row mt-5">
                <div class="card-body col-12 col-md-6 p-0">
                    <h4>Change account password</h4>
                    <div class="row mt-3">
                        <form v-on:submit.prevent="update__password" class="forms-sample text-center col-12 col-md-6">

                            <div class="input-group mb-3" id="show_hide_password">
                                <!-- <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">New password</span>
                                </div> -->
                                <input placeholder="New password" type="password" id="password" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__child_data.password">
                                <!-- <div class="input-group-append">
                                    <span class="input-group-text"><a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a></span>
                                </div> -->
                            </div>

                            <button type="submit" class="save_btn w-100">Save</button>
                        </form>
                    </div>
                </div>
                <div class="d-none d-md-flex col-3"></div>
            </div>










      </div>
    </div>
</template>

<script>
// import axios from "axios"
import router from "@/router"
var rldata = {
    selected_child: null,
    user: null,
    screen_flags: {
        show_child_info: false
    },

    new__child_data: {
        grade: null,
        password: '',
    },
}
export default {
  name: "Manage_Child",
  data () {
    return {
      rldata
    }
  },
  methods: {
    
    update__password: function(){
        if(this.rldata.new__child_data.password.length > 4 && this.rldata.new__child_data.password.length < 10){
            let data = {
                child_id: this.rldata.selected_child._id,
                password: this.rldata.new__child_data.password
            };
            this.$root.update__child_password(data);
        } else {
            alert('Nothing to update, or new password doesnt match');
        }
    },

    update__grade: function(){
        if(this.rldata.new__child_data.grade != null){
            let data = {
                child_id: this.rldata.selected_child._id,
                grade: this.rldata.new__child_data.grade
            };
            this.$root.update__child_grade(data);
        } else {
            alert('Nothing to update');
        }
    },

    add_social: function(event) {
        console.log('here');
        // localStorage.setItem('social_operation', 'connect');
        // localStorage.setItem('user_id', this.$root.user._id);
        let button = event.target.getAttribute('data-uloginbutton');
        console.log(button);
        // switch(button){
        //   case "VK":
        //     console.log('vk');
        //     window.location.href = `${this.$root.rdata.vk_to_profile}`;
        //     break;

        //   case "Facebook":
        //     console.log('Facebook');
        //     window.location.href = `${this.$root.rdata.facebook_to_profile}`;
        //     break;

        //   case "Google":
        //     console.log('Google');
        //     window.location.href = `${this.$root.rdata.google_to_profile}`;
        //     break;

        //   default:
        //   console.log('none');
        //   window.location.reload();
        // }
    },
  },
  mounted () {
    try{
        this.$root.getUserData(this.rldata);

        this.$root.getSelectedChildData(this.rldata);

        $(document).ready(function() {
            $("#show_hide_password a").on('click', function(event) {
                event.preventDefault();
                if($('#show_hide_password input').attr("type") == "text"){
                    $('#show_hide_password input').attr('type', 'password');
                    $('#show_hide_password i').addClass( "fa-eye-slash" );
                    $('#show_hide_password i').removeClass( "fa-eye" );
                }else if($('#show_hide_password input').attr("type") == "password"){
                    $('#show_hide_password input').attr('type', 'text');
                    $('#show_hide_password i').removeClass( "fa-eye-slash" );
                    $('#show_hide_password i').addClass( "fa-eye" );
                }
            });
        });
      } catch(err){
        this.$root.logout();
      }
  },
   watch: {
     "rldata.selected_child": function(handler){
       if(handler != null) {

        rldata.screen_flags.show_child_info = true;

       }
     }
   }
}
</script>

<style scoped>
  .social_img_cards {
    cursor: pointer;
    padding: 0px; 
    outline: none; 
    border: none; 
    border-radius: 0px;
    /* position: relative;  */
    display: inherit; 
    width: 32px; 
    height: 32px; 
    /* left: 0px; 
    top: 0px;  */
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(2%, -50%);
    box-sizing: content-box;
  }

  .social_google{
    background: url('/resource/img/social_logos.png') 0px -206px / 32px no-repeat;
  }

  .social_clever{
    background: url("/resource/img/social_logos.png") 0px -104px no-repeat;
  }

  .social_connect{
    z-index: 999;
    font-weight: 600;
    cursor: pointer;
  }
  .disabled_social {
    background-color: aliceblue;
  }
</style>